export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Irgendwas scheint kaputt zu sein...',
  subheader: 'Sieht aus, als hättest du dich ein wenig verlaufen',
  buttonText: 'Zur startseite zurückkehren',
}
